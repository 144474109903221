import React from "react";
import "../Styles/Solution.css";
import img1sol from "../Assets/sol/Group.png";
import img1sol12 from "../Assets/sol1pic.png";
import "bootstrap/dist/css/bootstrap.css";
import img3 from "../Assets/images/client_connect_app_img.png";
import img4 from "../Assets/images/client_keyboard_app.png";

function Solution1() {
  return (
    <>
      <div className="banner_Solution1">
        <div className="Solutionbanner1_section">
          <h2>
            Empowering Digital Transformation with Custom Portal Solutions
          </h2>
          <p>
            Custom portal solutions are revolutionizing digital transformation
            by empowering businesses to streamline processes, enhance
            collaboration, and deliver personalized experiences to stakeholders.
          </p>
        </div>
       
      </div>
      <section className="main_body_soltions">
        <section className="section1_solution">
          <div className="section1_content">
            <h3 style={{ color: "#3D4DAE" }}>
              CRM(Customer Relationship Management)
            </h3>
            <p style={{ color: "#686868", fontSize: "14px" }}>
              ClientConnect, developed by Askari Technologies, is a
              state-of-the-art CRM (Customer Relationship Management) software
              integrated with advanced AI features. It is designed to empower
              businesses with efficient customer management and engagement
              capabilities, enabling them to stay competitive and relevant in
              today's dynamic business environment. ClientConnect offers a
              comprehensive suite of tools and functionalities to streamline
              customer interactions, optimize sales processes, and drive
              business growth.
            </p>
          </div>
          <div className="section2_img">
            <img src={img1sol12} className="imgsizing_sol2" />
          </div>
        </section>
        <section className="section2_solution">
          <h2 style={{ color: "#3D4DAE",marginBottom:'40px',fontWeight:"bolder" }}>Features of ClientConnect</h2>
          <div className="card_container_solu1">
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intelligent Customer Engagement
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                   textAlign:"center"
                }}
              >
                Utilize AI algorithms to gain insights into customer behavior,
                preferences, and needs. Leverage chatbots and virtual assistants
                for personalized and round-the-clock customer support.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Enhanced Sales Automation
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Automate sales processes with intelligent lead scoring,
                prioritization, and predictive analytics. Identify cross-selling
                and upselling opportunities for increased revenue generation.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Advanced Data Analytics
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Extract actionable insights from customer data with AI-driven
                analytics. Track key performance indicators, monitor customer
                trends, and make informed business decisions.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Seamless Integration
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                }}
              >
                Integrate with existing systems and platforms, ensuring a
                unified view of customer data across touchpoints.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Workflow Automation
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Streamline and automate routine tasks to improve team
                productivity and handle customer inquiries efficiently.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Scalability and Customizability
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Tailor the solution to meet the specific requirements of
                businesses of all sizes. Customize workflows, data fields, and
                user interfaces.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Data Security and Compliance
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Adhere to industry standards and implement robust security
                measures to protect sensitive customer information and ensure
                compliance with data protection regulations.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intuitive User Experience
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                User-friendly interface with interactive dashboards for easy
                navigation and task management. Quick onboarding and intuitive
                learning curve.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Askari Technologies to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Data Security and Compliance
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Adhere to industry standards and implement robust security
                measures to protect sensitive customer information and ensure
                compliance with data protection regulations.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intuitive User Experience
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                User-friendly interface with interactive dashboards for easy
                navigation and task management. Quick onboarding and intuitive
                learning curve.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Askari Technologies to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="btn_sol1">
              <button className="btn_sub_col1">Request A Demo</button>
            </div>
          </div>
        </section>
      </section>

      <section className="section3_solution ">
        <h3 style={{ color: "white" }}>Benefits of ClientConnect</h3>
        <div className="card2_sol1_sub">
          <div className="sub_card_sol2">
            <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
              Improved Customer Satisfaction{" "}
            </h5>
            <p
              style={{ color: "#686868", fontSize: "12px", textAlign:"center"}}
            >
              Personalized engagement and round-the-clock support enhance
              customer satisfaction, leading to increased loyalty and retention.
            </p>
          </div>
          <div className="sub_card_sol2">
            <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
              Increased Sales Effectiveness
            </h5>
            <p
              style={{ color: "#686868", fontSize: "12px", textAlign:"center" }}
            >
              Increased Sales Effectiveness Automation and AI-powered analytics
              enable efficient lead management, identify opportunities, and
              improve sales performance.
            </p>
          </div>
          <div className="sub_card_sol2">
            <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
              Data-Driven Decision Making
            </h5>
            <p
              style={{ color: "#686868", fontSize: "12px", textAlign:"center" }}
            >
              Actionable insights from data analytics support informed business
              decisions, helping businesses adapt their strategies for better
              customer outcomes.
            </p>
          </div>
          <div className="sub_card_sol2">
            <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
              Increased Sales Effectiveness
            </h5>
            <p
              style={{ color: "#686868", fontSize: "12px",textAlign:"center" }}
            >
              Automation and AI-powered analytics enable efficient lead
              management, identify opportunities, and improve sales performance.
            </p>
          </div>
          <div className="sub_card_sol2">
            <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
              Data-Driven Decision Making
            </h5>
            <p
              style={{ color: "#686868", fontSize: "12px", textAlign:"center" }}
            >
              Actionable insights from data analytics support informed business
              decisions, helping businesses adapt their strategies for better
              customer outcomes.
            </p>
          </div>
        </div>
      </section>

      <div className="container mt-5 mb-5  ">
        <div className="row ">
          <div className="col-lg-8 ">
            <h1 className="text-askari-primary fw-bold mt-5">
              Industries and How ClientConnect Helps Them
            </h1>
            <p >
              E-commerce: ClientConnect enables personalized engagement and
              targeted marketing campaigns, helping e-commerce businesses
              improve customer satisfaction and increase conversions.
            </p>
          </div>
         
          
        </div>
        <div className="bottom_card_dol container">
        <div className="sub_cards_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Askari Technologies to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="sub_cards_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Askari Technologies to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="sub_cards_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Askari Technologies to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="sub_cards_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Askari Technologies to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="sub_cards_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Askari Technologies to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="sub_cards_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Askari Technologies to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="sub_cards_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Askari Technologies to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="sub_cards_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Support and Training
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
                Round-the-clock support and comprehensive training resources
                provided by Askari Technologies to ensure a smooth user
                experience.
              </p>
            </div>
            <div className="sub_cards_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
              E-commerce:
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
              ClientConnect enables personalized engagement and targeted marketing campaigns, helping e-commerce businesses improve customer satisfaction and increase conversions.
              </p>
            </div>
            <div className="sub_cards_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
              E-commerce:
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign:"center"
                }}
              >
              ClientConnect enables personalized engagement and targeted marketing campaigns, helping e-commerce businesses improve customer satisfaction and increase conversions.
              </p>
            </div>




</div>
      </div>

      <div className=" d-none d-sm-block ">
        <div className="empowering_business mt-5 ">
          <div className="text-center">
            <h3 className="text-askari-primary fw-bold">
              Empowering Businesses with AI-Powered CRM Capabilities
            </h3>
            <p className="mt-3">
              ClientConnect from Askari Technologies empowers businesses across
              industries with its AI-powered CRM capabilities. By providing
              personalized customer engagement, automating sales processes,
              offering advanced data analytics, and ensuring seamless
              integration, ClientConnect helps businesses unlock growth and
              success in their respective industries.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Solution1;
