import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/ProductEng.css";
import { HashLink } from 'react-router-hash-link';
import left2_ecom from '../Assets/mask_ecom.png'
import Technologywework from "../Components/Technologywework.jsx"
import Endtwo from "../Components/Endtwo.jsx"
import pic1 from "../Assets/pic1.png";

import pic3 from "../Assets/pic3.png";
import pic4 from "../Assets/pic4.png";
import pic5 from "../Assets/pic5.png";
import autoparts from "../Assets/autoparts.png";
import pharma from "../Assets/pharma.png";
import apple from "../Assets/apple.png";
import beauty from "../Assets/beauty.png";
import car from "../Assets/car.png";

import check from "../Assets/check.png";

import ecommerce_capab from "../Assets/ecommerce_capab.png";
import pe1 from "../Assets/pe1.png";
import pe2 from "../Assets/pe2.png";
import pe3 from "../Assets/pe3.png";
import pe4 from "../Assets/pe4.png";
import pe5 from "../Assets/pe5.png";
import mainbg from "../Assets/maingbg.png";
import left2 from "../Assets/left2.png";
import microsoft from "../Assets/microsoft.png";
import js from "../Assets/js.png";
import php from "../Assets/php.png";
import salesforce from "../Assets/salesforce.png";
import odoo from "../Assets/odoo.png";
import netcore from "../Assets/netCore.png";
import Contactsub from "./Contactsub";

const EcommerceService = () => {
  return (
  
    <div className="bodycontent">
      <div className="bannner-image">
        {/* <p className="text-center text-white ">
         
        </p> */}

        <div className="">
          <p style={{fontSize:"15px"}}>End to End</p>
          <br></br>
          <h1 style={{fontWeight:"bolder",marginTop:"3%"}} >
    
Ecommerce Development <br />
          </h1>
          <h1 style={{fontWeight:"bolder",marginTop:"1%"}} >
          Services
          </h1>
          <p style={{fontSize:"13px",marginBottom:"2%",marginTop:"-20px",width:"40%"}}>
          Elevate your customer experience through a harmonious blend of brand objectives and
cutting-edge technology stacks like Magento, Shopify, and BigCommerce. Unlock the potential
of eCommerce for various business models, including B2B, B2C, C2C, and more, by leveraging
our expertise in custom eCommerce development solutions.

          </p>
          <HashLink to="#contact-us">
            <button className="btn_pro">Request Consultation</button>
            </HashLink>
          
        </div>
      </div>
      <div className="row mainsection">
        <div className="col">
          <div class="image-container">
            
            <img src={ecommerce_capab} alt="PNG Image" class="overlay-image"  style={{width:"100%",height:"60%",marginTop:'20%'}}/>
          </div>
        </div>
        <div className="col servicespara" >
          <h3  >
          Tailoring Ecommerce  <br /> Development to Maximize {" "}
            <br /> Profits
          </h3>
          <p>
          Want to revolutionize your digital presence? We can accelerate your business with our custom eCommerce solutions and services. Our eCommerce experts can assist you in integrating features, customizing the frontend, and developing an e-store from scratch for Magento, Shopify, Drupal, and other platforms.
At Askari , 
          </p>
          <p>
        
          we provide bespoke development services to ensure that an eCommerce solution meets your business needs. While developing your e-store, our developers consider all the aspects related to design, features, tech stack, security, integrations, etc., to provide you with a fully functional e-store without any bugs or glitches.
          </p>

          
        </div>
      </div>
      <div className="container">
        <div className="align_product" style={{display:"flex",flexDirection:"column"}}>
        <h3 style={{alignSelf:"center"}} >Our Ecommerce Development Services and Expertise</h3>
          <p >
          Experience the power of our stable, secure, and adaptable eCommerce solutions that elevate
customer satisfaction, drive sales growth, and fuel business expansion!
          </p>
        </div>
      </div>
      <div class="image-row container">
    <div class="image-item">
      <img src={pe1} alt="Image 1" />
      {/* <div>
        <h5>Ecommerce Store Development</h5>
        <p>
        We specialize in crafting bespoke e-stores across diverse platforms such as Magento, Shopify,
Drupal, and more, guaranteeing a seamless buying experience. Regardless of the domain's
complexity, we possess the expertise to handle every aspect with utmost proficiency!

        </p>
      </div> */}
      
    </div>
    <div class="image-item">
      <img src={pe2} alt="Image 2" />
      {/* <div>
        <h5>Mobile Commerce App Creation:
</h5>
        <p>
        Harness the potential of our eCommerce development services to construct professional,
innovative, and meticulously tailored eCommerce applications that captivate and retain the ideal
audience

        </p>
      </div> */}
    </div>
    <div class="image-item">
      <img src={pe3} alt="Image 3" />
      {/* <div>
        <h5>Ecommerce Platform Aggregator
</h5>
        <p>
        If you seek a website or application that showcases a wide range of products from multiple
vendors, our development services can assist you in creating a robust platform capable of
accommodating diverse brands

        </p>
      </div> */}
     
    </div>
    <div class="image-item">
      <img src={pe4} alt="Image 4" />
   {/* <div>
        <h5>B2B and B2C Marketplaces
</h5>
        <p>
        Embark on the journey to create a cutting-edge eCommerce marketplace akin to Amazon and
eBay through our exceptional development services. Delight your customers with a
comprehensive shopping destination that offers everything they need in one place.

        </p>
      </div> */}
      </div>
     
     
  
    <div class="image-item">
      <img src={pe5} alt="Image 5" />
      {/* <div>
        <h5>Ecommerce Assistance and Upkeep
</h5>
        <p>
        Experience seamless support and management around the clock, available whenever you need
it. Our dedicated team ensures your eStore remains operational with regular updates and
maintenance, guaranteeing uninterrupted performance

        </p>
      </div> */}
     
    </div>
  </div>
      <Contactsub/>
      <Technologywework/>
      <div className="container mt-5">
        <h1 className="heading">Industries We Serve</h1>
        <h6 className="subHead mb-5">
          Lorem ipsum dolor sit amet, consectetur adipis elit
        </h6>
        <div class="container container-fluid">
          <div class="row">
            <div class="col-md-4 col-sm-12 column border_right_bottom">
              <img src={pic1} />
              <h5 className="name">Fashions & Clothing</h5>
            </div>
            <div class="col-md-4  col-sm-12  column border_right_bottom">
              <img src={pic3} />
              <h5 className="name">Retail</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={autoparts} />
              <h5 className="name">Auto Parts</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={pharma} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={apple} />
              <h5 className="name">Fitness & Nutrition</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={pic5} />
              <h5 className="name">Furniture</h5>
            </div>
          </div>
          <div className="row">
            <div class="col-md-4   col-sm-12 column border_right">
              <img src={pic4} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right">
              <img src={beauty} />
              <h5 className="name">Beauty And Personal Care</h5>
            </div>
            <div class="col-md-4  col-sm-12 column ">
              <img src={car} />
              <h5 className="name">Rental</h5>
            </div>
          </div>
        </div>
      </div>

      <section className="your_patner_pro ">
        <h2>More than Just Ecommerce Development Services</h2>
        <p>Development alone is not enough. Therefore, we provide an extensive array of professional
eCommerce development services that have the potential to revolutionize the way your
customers perceive your brand</p>
          
          {/* lower cards */}
          <div className="container card_pro_eng_botm">
            <div className="sub_card_pro_eng_botm">
              <div className="sub_card_pro_eng_head">
                    <img  src={require("../Assets/card_sub1.png")}/>
              </div>
              <div className="sub_card_pro_eng_footer">
                <center><h5 className="sub_card_heading">
                Efficiency
                </h5></center>
                <p className="para_pro_card_eng">
                Our eCommerce professionals analyze your store's strengths and provide recommendations to overcome obstacles. We optimize solutions to offer valuable insights on customer journey, purchase history, and cart abandonment data. Our aim is to create a personalized and tailored experience for your customers.
                </p>


              </div>
              
             
             

            </div>
            <div className="sub_card_pro_eng_botm">
              <div className="sub_card_pro_eng_head">
                    <img  src={require("../Assets/card_sub2.png")}/>
              </div>
              <div className="sub_card_pro_eng_footer">
               <center><h5 className="sub_card_heading">
                Insightful
                </h5></center> 
                <p className="para_pro_card_eng">
                Unlock growth potential through strategic consulting in eCommerce. Drive traffic, increase conversions, and optimize sales while streamlining operational processes. Leverage our expertise in marketing, technology, operations, and customer engagement to gain valuable insights into user journeys and buying behaviors.
                </p>


              </div>
              
             
             

            </div>
            
            <div className="sub_card_pro_eng_botm">
              <div className="sub_card_pro_eng_head">
                    <img  src={require("../Assets/crad_sub3.png")}/>
              </div>
              <div className="sub_card_pro_eng_footer">
               <center><h5 className="sub_card_heading">
               Analytics
                </h5></center> 
                <p className="para_pro_card_eng">
                Experience seamless data management for your eCommerce business. Our cutting-edge services handle  data processing needs, including store catalog maintenance, product data uploads, and efficient data organization. Count on us to keep your data impeccably organized and readily accessible, empowering your business to thrive.                </p>


              </div>
              
             
             

            </div>
            <div className="sub_card_pro_eng_botm">
              <div className="sub_card_pro_eng_head">
                    <img  src={require("../Assets/crad_sub4.png")}/>
              </div>
              <div className="sub_card_pro_eng_footer">
               <center> <h5 className="sub_card_heading">
                Ecommerce Audit
                </h5></center>
                <p className="para_pro_card_eng">
                Our comprehensive eCommerce site auditing examines vital areas like SEO, content,
performance, traffic, and conversion to uncover improvement opportunities and drive better
outcomes.                </p>


              </div>
              
             
             

            </div>
            <div className="sub_card_pro_eng_botm">
              <div className="sub_card_pro_eng_head">
                    <img  src={require("../Assets/crad_sub5.png")}/>
              </div>
              <div className="sub_card_pro_eng_footer">
                <center><h5 className="sub_card_heading">
                Promotion
                </h5></center>
                <p className="para_pro_card_eng">
                Maximize conversions and performance of your online store through customized eCommerce
marketing and automation solutions. Leverage omnichannel marketing strategies infused with
impactful insights to effectively engage potential customers and make data-driven decisions                </p>


              </div>
              
             
             

            </div>

          </div>



      </section>

      <div className="bg_baneer_proemg">
       
       </div>
<Endtwo/>
      
      
      
    </div>
  );
};

export default EcommerceService;
