import React from "react";
import "./Hearder.css";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import Software from "./Assets/Frame.png";
import InterLogo from "./Assets/ecomlogo.png";
import Two from "./Assets/two.png";
import Three from "./Assets/three.png";
import Four from "./Assets/four.png";
import Five from "./Assets/five.png";
import Six from "./Assets/six.png";
import Seven from "./Assets/seven.png";
import Eight from "./Assets/eight.png";
import Nine from "./Assets/nine.png";
import Ten from "./Assets/ten.png";
import Webdev from "./Assets/webdev.png";
import Appdev from "./Assets/appdev.png";
import Interprise from "./Assets/interprise.png";
import Software1 from "./Assets/Frame.png";
import Contant from "./Assets/contant.png";
import Consultant from "./Assets/consultant.png";
import Social from "./Assets/social.png";
import Automation from "./Assets/automation.png";
import Creative from "./Assets/creative.png";
import AIML from "./Assets/aiml.png";








var Whatwedo = () => {
  var abbass1 = () => {
    const butt1= document.getElementById("first11");
    butt1.style.display="block";
    const butt21= document.getElementById("second11");
    butt21.style.display="none";
    const butt31= document.getElementById("third11");
    butt31.style.display="none";   
    const butt41= document.getElementById("fourth11");
    butt41.style.display="none";   
    const butt51= document.getElementById("fifth11");
    butt51.style.display="none";
    const butt12= document.getElementById("six11");
    butt12.style.display="none";
    const butt212= document.getElementById("seven11");
    butt212.style.display="none";
    const butt312= document.getElementById("eight11");
    butt312.style.display="none";   
    const butt412= document.getElementById("nine11");
    butt412.style.display="none";   
    const butt512= document.getElementById("ten11");
    butt512.style.display="none";
  
  };
  var abbass2 = () => {
    const butt1= document.getElementById("first11");
    butt1.style.display="none";
    const butt21= document.getElementById("second11");
    butt21.style.display="block";
    const butt31= document.getElementById("third11");
    butt31.style.display="none";   
    const butt41= document.getElementById("fourth11");
    butt41.style.display="none";   
    const butt51= document.getElementById("fifth11");
    butt51.style.display="none";
    const butt12= document.getElementById("six11");
    butt12.style.display="none";
    const butt212= document.getElementById("seven11");
    butt212.style.display="none";
    const butt312= document.getElementById("eight11");
    butt312.style.display="none";   
    const butt412= document.getElementById("nine11");
    butt412.style.display="none";   
    const butt512= document.getElementById("ten11");
    butt512.style.display="none";
  };
   var abbass3 = () => {
    const butt1= document.getElementById("first11");
    butt1.style.display="none";
    const butt21= document.getElementById("second11");
    butt21.style.display="none";
    const butt31= document.getElementById("third11");
    butt31.style.display="block";   
    const butt41= document.getElementById("fourth11");
    butt41.style.display="none";   
    const butt51= document.getElementById("fifth11");
    butt51.style.display="none";
    const butt12= document.getElementById("six11");
    butt12.style.display="none";
    const butt212= document.getElementById("seven11");
    butt212.style.display="none";
    const butt312= document.getElementById("eight11");
    butt312.style.display="none";   
    const butt412= document.getElementById("nine11");
    butt412.style.display="none";   
    const butt512= document.getElementById("ten11");
    butt512.style.display="none";
  };
  var abbass4 = () => {
    const butt1= document.getElementById("first11");
    butt1.style.display="none";
    const butt21= document.getElementById("second11");
    butt21.style.display="none";
    const butt31= document.getElementById("third11");
    butt31.style.display="none";   
    const butt41= document.getElementById("fourth11");
    butt41.style.display="block";   
    const butt51= document.getElementById("fifth11");
    butt51.style.display="none";
    const butt12= document.getElementById("six11");
    butt12.style.display="none";
    const butt212= document.getElementById("seven11");
    butt212.style.display="none";
    const butt312= document.getElementById("eight11");
    butt312.style.display="none";   
    const butt412= document.getElementById("nine11");
    butt412.style.display="none";   
    const butt512= document.getElementById("ten11");
    butt512.style.display="none";
  };
  var abbass5 = () => {
    const butt1= document.getElementById("first11");
    butt1.style.display="none";
    const butt21= document.getElementById("second11");
    butt21.style.display="none";
    const butt31= document.getElementById("third11");
    butt31.style.display="none";   
    const butt41= document.getElementById("fourth11");
    butt41.style.display="none";   
    const butt51= document.getElementById("fifth11");
    butt51.style.display="block";
    const butt12= document.getElementById("six11");
    butt12.style.display="none";
    const butt212= document.getElementById("seven11");
    butt212.style.display="none";
    const butt312= document.getElementById("eight11");
    butt312.style.display="none";   
    const butt412= document.getElementById("nine11");
    butt412.style.display="none";   
    const butt512= document.getElementById("ten11");
    butt512.style.display="none";
  };
  var abbass6 = () => {
    const butt1= document.getElementById("first11");
    butt1.style.display="none";
    const butt21= document.getElementById("second11");
    butt21.style.display="none";
    const butt31= document.getElementById("third11");
    butt31.style.display="none";   
    const butt41= document.getElementById("fourth11");
    butt41.style.display="none";   
    const butt51= document.getElementById("fifth11");
    butt51.style.display="none";
    const butt12= document.getElementById("six11");
    butt12.style.display="block";
    const butt212= document.getElementById("seven11");
    butt212.style.display="none";
    const butt312= document.getElementById("eight11");
    butt312.style.display="none";   
    const butt412= document.getElementById("nine11");
    butt412.style.display="none";   
    const butt512= document.getElementById("ten11");
    butt512.style.display="none";
  };
  var abbass7 = () => {
    const butt1= document.getElementById("first11");
    butt1.style.display="none";
    const butt21= document.getElementById("second11");
    butt21.style.display="none";
    const butt31= document.getElementById("third11");
    butt31.style.display="none";   
    const butt41= document.getElementById("fourth11");
    butt41.style.display="none";   
    const butt51= document.getElementById("fifth11");
    butt51.style.display="none";
    const butt12= document.getElementById("six11");
    butt12.style.display="none";
    const butt212= document.getElementById("seven11");
    butt212.style.display="block";
    const butt312= document.getElementById("eight11");
    butt312.style.display="none";   
    const butt412= document.getElementById("nine11");
    butt412.style.display="none";   
    const butt512= document.getElementById("ten11");
    butt512.style.display="none";
  };
  var abbass8 = () => {
    const butt1= document.getElementById("first11");
    butt1.style.display="none";
    const butt21= document.getElementById("second11");
    butt21.style.display="none";
    const butt31= document.getElementById("third11");
    butt31.style.display="none";   
    const butt41= document.getElementById("fourth11");
    butt41.style.display="none";   
    const butt51= document.getElementById("fifth11");
    butt51.style.display="none";
    const butt12= document.getElementById("six11");
    butt12.style.display="none";
    const butt212= document.getElementById("seven11");
    butt212.style.display="none";
    const butt312= document.getElementById("eight11");
    butt312.style.display="block";   
    const butt412= document.getElementById("nine11");
    butt412.style.display="none";   
    const butt512= document.getElementById("ten11");
    butt512.style.display="none";
  };
  var abbass9 = () => {
    const butt1= document.getElementById("first11");
    butt1.style.display="none";
    const butt21= document.getElementById("second11");
    butt21.style.display="none";
    const butt31= document.getElementById("third11");
    butt31.style.display="none";   
    const butt41= document.getElementById("fourth11");
    butt41.style.display="none";   
    const butt51= document.getElementById("fifth11");
    butt51.style.display="none";
    const butt12= document.getElementById("six11");
    butt12.style.display="none";
    const butt212= document.getElementById("seven11");
    butt212.style.display="none";
    const butt312= document.getElementById("eight11");
    butt312.style.display="none";   
    const butt412= document.getElementById("nine11");
    butt412.style.display="block";   
    const butt512= document.getElementById("ten11");
    butt512.style.display="none";
  };
  var abbass10 = () => {
    const butt1= document.getElementById("first11");
    butt1.style.display="none";
    const butt21= document.getElementById("second11");
    butt21.style.display="none";
    const butt31= document.getElementById("third11");
    butt31.style.display="none";   
    const butt41= document.getElementById("fourth11");
    butt41.style.display="none";   
    const butt51= document.getElementById("fifth11");
    butt51.style.display="none";
    const butt12= document.getElementById("six11");
    butt12.style.display="none";
    const butt212= document.getElementById("seven11");
    butt212.style.display="none";
    const butt312= document.getElementById("eight11");
    butt312.style.display="none";   
    const butt412= document.getElementById("nine11");
    butt412.style.display="none";   
    const butt512= document.getElementById("ten11");
    butt512.style.display="block";
  };
//   var abbass3 = () => {
//     var butt = document.getElementById("first1");
//     butt.style.display = "none";
//     var butt2 = document.getElementById("second1");
//     butt2.style.display = "none";
//     var butt3 = document.getElementById("third1");
//     butt3.style.display = "none";
//     var butt4 = document.getElementById("fourth1");
//     butt4.style.display = "none";
//     var butt5 = document.getElementById("fifth1");
//     butt5.style.display = "block";
//     var butt6 = document.getElementById("sixth1");
//     butt6.style.display = "none";
//     var butt7 = document.getElementById("seventh1");
//     butt7.style.display = "none";
//     var butt8 = document.getElementById("eight1");
//     butt8.style.display = "none";
//     var butt9 = document.getElementById("nine1");
//     butt9.style.display = "none";
//     var butt10 = document.getElementById("ten1");
//     butt10.style.display = "block";
//   };
//   var abbass4 = () => {
//     var butt = document.getElementById("first1");
//     butt.style.display = "none";
//     var butt2 = document.getElementById("second1");
//     butt2.style.display = "none";
//     var butt3 = document.getElementById("third1");
//     butt3.style.display = "none";
//     var butt4 = document.getElementById("fourth1");
//     butt4.style.display = "none";
//     var butt5 = document.getElementById("fifth1");
//     butt5.style.display = "block";
//     var butt6 = document.getElementById("sixth1");
//     butt6.style.display = "none";
//     var butt7 = document.getElementById("seventh1");
//     butt7.style.display = "none";
//     var butt8 = document.getElementById("eight1");
//     butt8.style.display = "none";
//     var butt9 = document.getElementById("nine1");
//     butt9.style.display = "none";
//     var butt10 = document.getElementById("ten1");
//     butt10.style.display = "block";
//   };
//   var abbass5 = () => {
//     var butt = document.getElementById("first1");
//     butt.style.display = "none";
//     var butt2 = document.getElementById("second1");
//     butt2.style.display = "none";
//     var butt3 = document.getElementById("third1");
//     butt3.style.display = "none";
//     var butt4 = document.getElementById("fourth1");
//     butt4.style.display = "none";
//     var butt5 = document.getElementById("fifth1");
//     butt5.style.display = "block";
//     var butt6 = document.getElementById("sixth1");
//     butt6.style.display = "none";
//     var butt7 = document.getElementById("seventh1");
//     butt7.style.display = "none";
//     var butt8 = document.getElementById("eight1");
//     butt8.style.display = "none";
//     var butt9 = document.getElementById("nine1");
//     butt9.style.display = "none";
//     var butt10 = document.getElementById("ten1");
//     butt10.style.display = "block";
//   };
//   var abbass6 = () => {
//     var butt = document.getElementById("first1");
//     butt.style.display = "none";
//     var butt2 = document.getElementById("second1");
//     butt2.style.display = "none";
//     var butt3 = document.getElementById("third1");
//     butt3.style.display = "none";
//     var butt4 = document.getElementById("fourth1");
//     butt4.style.display = "none";
//     var butt5 = document.getElementById("fifth1");
//     butt5.style.display = "block";
//     var butt6 = document.getElementById("sixth1");
//     butt6.style.display = "none";
//     var butt7 = document.getElementById("seventh1");
//     butt7.style.display = "none";
//     var butt8 = document.getElementById("eight1");
//     butt8.style.display = "none";
//     var butt9 = document.getElementById("nine1");
//     butt9.style.display = "none";
//     var butt10 = document.getElementById("ten1");
//     butt10.style.display = "block";
//   };
//   var abbass7 = () => {
//     var butt = document.getElementById("first1");
//     butt.style.display = "none";
//     var butt2 = document.getElementById("second1");
//     butt2.style.display = "none";
//     var butt3 = document.getElementById("third1");
//     butt3.style.display = "none";
//     var butt4 = document.getElementById("fourth1");
//     butt4.style.display = "none";
//     var butt5 = document.getElementById("fifth1");
//     butt5.style.display = "block";
//     var butt6 = document.getElementById("sixth1");
//     butt6.style.display = "none";
//     var butt7 = document.getElementById("seventh1");
//     butt7.style.display = "none";
//     var butt8 = document.getElementById("eight1");
//     butt8.style.display = "none";
//     var butt9 = document.getElementById("nine1");
//     butt9.style.display = "none";
//     var butt10 = document.getElementById("ten1");
//     butt10.style.display = "block";
//   };
//   var abbass8 = () => {
//     var butt = document.getElementById("first1");
//     butt.style.display = "none";
//     var butt2 = document.getElementById("second1");
//     butt2.style.display = "none";
//     var butt3 = document.getElementById("third1");
//     butt3.style.display = "none";
//     var butt4 = document.getElementById("fourth1");
//     butt4.style.display = "none";
//     var butt5 = document.getElementById("fifth1");
//     butt5.style.display = "block";
//     var butt6 = document.getElementById("sixth1");
//     butt6.style.display = "none";
//     var butt7 = document.getElementById("seventh1");
//     butt7.style.display = "none";
//     var butt8 = document.getElementById("eight1");
//     butt8.style.display = "none";
//     var butt9 = document.getElementById("nine1");
//     butt9.style.display = "none";
//     var butt10 = document.getElementById("ten1");
//     butt10.style.display = "block";
//   };

//   var abbass9 = () => {
//     var butt = document.getElementById("first1");
//     butt.style.display = "none";
//     var butt2 = document.getElementById("second1");
//     butt2.style.display = "none";
//     var butt3 = document.getElementById("third1");
//     butt3.style.display = "none";
//     var butt4 = document.getElementById("fourth1");
//     butt4.style.display = "none";
//     var butt5 = document.getElementById("fifth1");
//     butt5.style.display = "block";
//     var butt6 = document.getElementById("sixth1");
//     butt6.style.display = "none";
//     var butt7 = document.getElementById("seventh1");
//     butt7.style.display = "none";
//     var butt8 = document.getElementById("eight1");
//     butt8.style.display = "none";
//     var butt9 = document.getElementById("nine1");
//     butt9.style.display = "none";
//     var butt10 = document.getElementById("ten1");
//     butt10.style.display = "block";
//   };

//   var abbass10 = () => {
//     var butt = document.getElementById("first1");
//     butt.style.display = "none";
//     var butt2 = document.getElementById("second1");
//     butt2.style.display = "none";
//     var butt3 = document.getElementById("third1");
//     butt3.style.display = "none";
//     var butt4 = document.getElementById("fourth1");
//     butt4.style.display = "none";
//     var butt5 = document.getElementById("fifth1");
//     butt5.style.display = "block";
//     var butt6 = document.getElementById("sixth1");
//     butt6.style.display = "none";
//     var butt7 = document.getElementById("seventh1");
//     butt7.style.display = "none";
//     var butt8 = document.getElementById("eight1");
//     butt8.style.display = "none";
//     var butt9 = document.getElementById("nine1");
//     butt9.style.display = "none";
//     var butt10 = document.getElementById("ten1");
//     butt10.style.display = "block";
//   };

  return (
    <>
      <div className="container-fluid" style={{textAlign:'center'}}>
        <h2 className="whatwedo">What We Do</h2>
        <p className="parawhat">
          Expertise, thorough knowledge and firsthand experience along with
          strong Industry specific research skills help us in analyzing,
          <br />
          designing and implementing world class solutions.
        </p>
        <div className="container">
          <div className="row">
            <div className="col" id="first11">
            <div className="container webgrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{color:'#EC9B00'}}>Website Development</h3>
                  <p>
                  Web development services are the core of our business. We provide a wide range of professional services in the development of web 2.0 and intranet applications of any complexity. Our highly talented team endeavors to develop web applications that can become an invaluable asset for your company.

                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Custom Web Design & Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Web Apps</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Enterprise Portal Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Application Maintenance</li>
                      </div>
                      
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#EC9B00',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Webdev} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="second11" >
            <div className="container appgrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{color:'#6471D7'}} >Application Development</h3>
                  <p>
                  Develop, test, and deploy custom mobile apps using a single code for all devices, browsers, and operating systems. At Askari Tech, we provide the tools and technologies that enterprises need to demystify and accelerate mobile app development.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Enterprise App Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile UI/UX Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile POS Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Maintenance and Support</li>
                      </div>
                      
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#6471D7',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Appdev} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="third11">
            <div className="container entergrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{color:'#BF9752'}}>Enterprise</h3>
                  <p>
                  Develop, test, and deploy custom mobile apps using a single code for all devices, browsers, and operating systems. At Askari Tech, we provide the tools and technologies that enterprises need to demystify and accelerate mobile app development.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Enterprise App Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile UI/UX Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>App Porting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile POS Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Maintenance and Support</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#BF9752',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Interprise} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="fourth11">     <div className="container softwaregrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{color:'#6471D7'}}>Software Development</h3>
                  <p>
                  Develop, test, and deploy custom mobile apps using a single code for all devices, browsers, and operating systems. At Askari Tech, we provide the tools and technologies that enterprises need to demystify and accelerate mobile app development.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Enterprise App Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile UI/UX Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>App Porting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile POS Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Maintenance and Support</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#6471D7',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Software1} className="navimg" />
                  </div>
                </div>
              </div></div>
            <div className="col hideOptions" id="fifth11">
            <div className="container contantgrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{color:'#20AF92'}}>Content Management</h3>
                  <p>
                  Develop, test, and deploy custom mobile apps using a single code for all devices, browsers, and operating systems. At Askari Tech, we provide the tools and technologies that enterprises need to demystify and accelerate mobile app development.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Enterprise App Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile UI/UX Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>App Porting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile POS Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Maintenance and Support</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#20AF92',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Contant} className="navimg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col hideOptions" id="six11">
            <div className="container creativegrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{color:'#4C7CE0'}}>Creative</h3>
                  <p>
                  Develop, test, and deploy custom mobile apps using a single code for all devices, browsers, and operating systems. At Askari Tech, we provide the tools and technologies that enterprises need to demystify and accelerate mobile app development.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Enterprise App Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile UI/UX Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>App Porting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile POS Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Maintenance and Support</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#4C7CE0',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Creative} className="navimg" />
                  </div>
                </div>
              </div>
              </div>
              <div className="col hideOptions" id="seven11">
              <div className="container socialgrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{color:'#C86485'}}>Social Media Services</h3>
                  <p>
                  Develop, test, and deploy custom mobile apps using a single code for all devices, browsers, and operating systems. At Askari Tech, we provide the tools and technologies that enterprises need to demystify and accelerate mobile app development.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Enterprise App Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile UI/UX Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>App Porting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile POS Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Maintenance and Support</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#C86485',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Social} className="navimg" />
                  </div>
                </div>
              </div>
              </div>
              <div className="col  hideOptions" id="eight11">
              <div className="container autograd">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{color:'#FE776B'}}>Automation Services</h3>
                  <p>
                  Develop, test, and deploy custom mobile apps using a single code for all devices, browsers, and operating systems. At Askari Tech, we provide the tools and technologies that enterprises need to demystify and accelerate mobile app development.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Enterprise App Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile UI/UX Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>App Porting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile POS Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Maintenance and Support</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#FE776B',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Automation} className="navimg" />
                  </div>
                </div>
              </div>
              </div>
              <div className="col hideOptions" id="nine11">
              <div className="container aigrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{color:'#58B5D9'}}>AI/ML Services</h3>
                  <p>
                  Give your customers the best of humans and technology with AI and ML solutions. Leverage the power of sentiment analysis, predictive models, IoT-enabled systems, etc., to prevent risks, automate workflows, measure performance, and build better customer relations.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Enterprise App Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile UI/UX Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>App Porting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile POS Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Maintenance and Support</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#58B5D9',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={AIML} className="navimg" />
                  </div>
                </div>
              </div>
              </div>
              <div className="col hideOptions" id="ten11">
              <div className="container colgrad">
                <div className="row divHeader">
                  <h3 className="backendHeading" style={{color:'#277796'}}>Consulting Services</h3>
                  <p>
                  Develop, test, and deploy custom mobile apps using a single code for all devices, browsers, and operating systems. At Askari Tech, we provide the tools and technologies that enterprises need to demystify and accelerate mobile app development.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Enterprise App Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile UI/UX Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>App Porting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile POS Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Maintenance and Support</li>
                      </div>
                          
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#277796',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Consultant} className="navimg" />
                  </div>
                </div>
              </div>
              </div>
           
            <div className="col-md-5 col-sm-12 ">
              <div className="overflow">
                <div className="whatwedoBtn  " onClick={abbass1}>
                  <img src={InterLogo} className="logowhat" />
                  <h4 className="orange">Website Development</h4>
                </div>
                <div className="whatwedoBtn1" onClick={abbass2}>
                  <img src={Two} className="logowhat" />
                  <h4 className="orange">App Development</h4>
                </div>
                <div className="whatwedoBtn2" onClick={abbass3} >
                  <img src={Three} className="logowhat" />
                  <h4 className="orange">Enterprise</h4>
                </div>
                <div className="whatwedoBtn3" onClick={abbass4}>
                  <img src={Four} className="logowhat" />
                  <h4 className="orange  mobileres">Software Development</h4>
                </div>
                <div className="whatwedoBtn4"onClick={abbass5}>
                  <img src={Five} className="logowhat" />
                  <h4 className="orange mobileres">Content Management</h4>
                </div>
                <div className="whatwedoBtn5" onClick={abbass6} >
                  <img src={Six} className="logowhat" />
                  <h4 className="orange">Creative</h4>
                </div>
                <div className="whatwedoBtn6" onClick={abbass7} >
                  <img src={Seven} className="logowhat" />
                  <h4 className="orange">Social Media Services</h4>
                </div>
                <div className="whatwedoBtn7" onClick={abbass8} >
                  <img src={Eight} className="logowhat" />
                  <h4 className="orange">Automation Services</h4>
                </div>
                <div className="whatwedoBtn8" onClick={abbass9}>
                  <img src={Nine} className="logowhat" />
                  <h4 className="orange">AI/ML Services</h4>
                </div>
                <div className="whatwedoBtn9" onClick={abbass10} >
                  <img src={Ten} className="logowhat" />
                  <h4 className="orange">Consulting Services</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Whatwedo;
